import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const windowGlobal = typeof window !== 'undefined' && window;


class CareersPage extends React.Component {
  componentDidMount() {

    windowGlobal._rbox = {
      host_protocol: document.location.protocol,
      ready: function(cb) {
        this.onready = cb
      },
    }
    ;(function(d, e) {
      var s,
        t,
        i,
        src = [
          "/static/client-src-served/widget/21838/rbox_api.js",
          "/static/client-src-served/widget/21838/rbox_impl.js",
        ]
      t = d.getElementsByTagName(e)
      t = t[t.length - 1]
      for (i = 0; i < src.length; i++) {
        s = d.createElement(e)
        s.src = windowGlobal._rbox.host_protocol + "//w.recruiterbox.com" + src[i]
        t.parentNode.insertBefore(s, t.nextSibling)
      }
    })(document, "script")

  }
  render() {
    return (
      <Layout>
        <SEO title="Careers" />
        <div id="careers">
            <div className="container">
          <h4>Careers at Sostena</h4>
          <div id="meteorrboxwidget">
            <div id="rbox-loader-script"></div>
            <div className="rbox-opening-list"></div>
            <div className="rbox-opening-detail"></div>
          </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default CareersPage
